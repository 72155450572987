import { useState, useEffect, useRef, useContext } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Toast } from 'primereact/toast'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { InputSwitch } from 'primereact/inputswitch'

import { AppointmentService } from './appointmentService'
import { DealershipUsersUnavailableIntervalsService } from '../unavailableIntervals/dealershipUsersUnavailableIntervalsService'
import showToast from '../shared/ShowToast'
import * as Routes from '../../routes'
import Modal from '../entries/modal'
import { CurrentUserContext, DealershipContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'

const AppointmentModal = ({
  isOpen,
  onClose,
  selectedInfo,
  navigateToLeadCluster,
  navigateToContact,
}) => {
  const appointment = selectedInfo?.extendedProps
  if (!appointment) return null
  const testDriveUrl = `/dealerships/${appointment.dealership_id}/test_drives/${appointment.schedulable_id}/edit`

  return (
    <Modal
      id={`new-appointment`}
      header={`Appointment Details`}
      width="md"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="p-3 border rounded shadow-sm bg-light">
        <div className="mb-1">
          <strong>Contact:</strong>{' '}
          <a href="#" onClick={navigateToContact}>
            {appointment.contact_name}
          </a>
        </div>
        <div className="mb-1">
          <strong>Assignee:</strong> {appointment.assignee_name}
        </div>
        <div className="mb-1">
          <strong>Date/Time:</strong>{' '}
          {selectedInfo?.start
            ? selectedInfo.start.toLocaleString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
            : 'N/A'}
        </div>
        <div className="mb-1">
          <strong>Length:</strong> {appointment.length_in_minutes} minutes
        </div>
        <div className="mb-1">
          <strong>Status:</strong> {appointment.status}
        </div>
        {appointment.car_for_test_drive && appointment.schedulable_type === 'TestDrive' && (
          <div className="mb-1">
            <strong>Purpose:</strong> <Link to={testDriveUrl} target="_blank" rel="noopener noreferrer" >Test Drive for {appointment.car_for_test_drive}</Link>
          </div>
        )}
        <div className="mb-1">
          <strong>Notes:</strong>
          <div style={{ wordWrap: 'break-word' }}>
            <span className="text-secondary">{appointment.note || 'N/A'}</span>
          </div>
        </div>

        {appointment.lead_cluster_id ? (
          <button className={`btn btn-success btn-block mt-2`} onClick={navigateToLeadCluster}>
            View Lead Cluster
          </button>
        ) : (
          'N/A'
        )}
      </div>
    </Modal>
  )
}

const EmailModal = ({
  isOpen,
  onClose,
  selectedInfo,
  notifyAssignee,
  notifyContact,
  setNotifyAssignee,
  setNotifyContact,
  updateAppointment,
}) => {
  const appointment = selectedInfo?.extendedProps
  if (!appointment) return null
  return (
    <Modal
      id={`new-email`}
      header={`Update Appointment Details`}
      width="md"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="p-3 border rounded shadow-sm bg-light">
        <div className="mb-1">
          <strong>Contact:</strong> {appointment.contact_name}
        </div>
        <div className="mb-1">
          <strong>Assignee:</strong> {appointment.assignee_name}
        </div>
        <div className="mb-1">
          <strong>Date/Time:</strong>{' '}
          {selectedInfo?.start
            ? selectedInfo.start.toLocaleString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
            : 'N/A'}
        </div>
        <div className="mb-1">
          <strong>Length:</strong> {appointment.length_in_minutes} minutes
        </div>
        <div className="mb-1">
          <strong>Status:</strong> {appointment.status}
        </div>
        <div className="mb-1">
          <strong>Notes:</strong>
          <div style={{ wordWrap: 'break-word' }}>
            <span className="text-secondary">{appointment.note || 'N/A'}</span>
          </div>
        </div>
        <div className="mb-1">
          <strong>Notify Assignee:</strong>{' '}
          <InputSwitch
            id="notifyAssignee"
            checked={notifyAssignee}
            onChange={(e) => setNotifyAssignee(e.value)}
            style={{ marginLeft: '10px', width: '40px', height: '20px' }}
          />
        </div>
        <div className="mb-1">
          <strong>Notify Contact:</strong>{' '}
          <InputSwitch
            id="notifyContact"
            checked={notifyContact}
            onChange={(e) => setNotifyContact(e.value)}
            style={{ marginLeft: '10px', width: '40px', height: '20px' }}
          />
        </div>
        {appointment.lead_cluster_id ? (
          <button className={`btn btn-success btn-block mt-2`} onClick={updateAppointment}>
            Update Appointment
          </button>
        ) : (
          'N/A'
        )}
      </div>
    </Modal>
  )
}

const AssigneeSelect = ({
  appointmentAssignees,
  selectedAppointmentAssignee,
  handleSelectChange,
}) => (
  <Select
    id="appointmentAssignees"
    name="appointmentAssignees"
    options={appointmentAssignees}
    isClearable={true}
    placeholder="Appointment Assignee"
    value={selectedAppointmentAssignee}
    onChange={handleSelectChange}
    style={{ width: 'auto', minWidth: '100px' }}
    styles={{
      menu: (provided) => ({ ...provided, zIndex: 2 }),
    }}
  />
)

export default function AppointmentsCalendar() {
  const currentUser = useContext(CurrentUserContext)
  const { dealership } = useContext(DealershipContext)
  const notification = useRef(null)
  const [appointments, setAppointments] = useState([])
  const [unavailableIntervals, setUnavailableIntervals] = useState([])
  const [events, setEvents] = useState([])
  const calendarRef = useRef(null)
  const [opened, setOpened] = useState(false)
  const [emailModalOpened, setEmailModalOpened] = useState(false)
  const [selectedInfo, setSelectedInfo] = useState(null)
  const [appointmentAssignees, setAppointmentAssignees] = useState([])
  const [selectedAppointmentAssignee, setSelectedAppointmentAssignee] = useState('')
  const userId = currentUser?.id
  const dealershipId = dealership?.id
  const [notifyAssignee, setNotifyAssignee] = useState(false)
  const [notifyContact, setNotifyContact] = useState(false)

  useFetchDealership()

  const loadData = () => {
    let params = {
      dealership_id: dealershipId,
    }
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    AppointmentService.getAppointments(params, csrf, dealershipId).then((data) => {
      setAppointments(data.data.appointments)
      setAppointmentAssignees(
        data.data.appointment_assignees.map((appointmentAssignee) => ({
          value: appointmentAssignee.id,
          label: appointmentAssignee.name,
        }))
      )
    })
  }

  useEffect(() => {
    setEvents([...appointments, ...unavailableIntervals])
  }, [appointments, unavailableIntervals])

  useEffect(() => {
    if (dealershipId) {
      loadData()
    }
  }, [dealershipId])

  const handleEventClick = (arg) => {
    setOpened(true)
    setSelectedInfo(arg.event)
    $(`#new-appointment`).modal()
  }

  const navigateToLeadCluster = () => {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    let pagesRoute = ''
    let leadClusterId = selectedInfo.extendedProps.lead_cluster_id
    pagesRoute = Routes.dealership_lead_cluster_path(dealershipId, leadClusterId)
    fetch(`${pagesRoute}.json`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': csrf,
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          let leadClusterUrl = Routes.dealership_lead_cluster_path(dealershipId, leadClusterId)
          window.location.href = leadClusterUrl
        })
      }
    })
  }

  const navigateToContact = () => {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    let pagesRoute = ''
    let contactId = selectedInfo.extendedProps.contact_id
    pagesRoute = Routes.dealership_contact_path(dealershipId, contactId)
    fetch(`${pagesRoute}.json`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': csrf,
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          let contactUrl = Routes.dealership_contact_path(dealershipId, contactId)
          window.open(contactUrl, '_blank')
        })
      }
    })
  }

  const navigateToUnavailableIntervals = () => {
    let pagesRoute = Routes.dealership_users_unavailable_intervals_path(dealershipId)
    window.location.href = pagesRoute
  }

  const handleEventResize = (eventResizeInfo) => {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    const { event, endDelta } = eventResizeInfo
    const { length_in_minutes } = event.extendedProps

    const params = {
      appointment: { length_in_minutes: length_in_minutes + endDelta.milliseconds / 60000 },
    }

    const pagesRoute = Routes.dealership_appointment_path(dealershipId, event.id)
    fetch(`${pagesRoute}.json`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
      body: JSON.stringify(params),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          showToast(notification, 'success', 'Appointment updated successfully')
        })
      } else {
        showToast(notification, 'error', 'Appointment could not be updated')
      }
    })
  }

  const handleEventDrop = (eventDropInfo) => {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    const { event } = eventDropInfo
    let startAt = new Date(eventDropInfo.event.start)
    const params = { appointment: { starts_at: startAt } }
    // show notification if the assignee is different from the current user
    if (event.extendedProps.user_id != userId) {
      setEmailModalOpened(true)
      setSelectedInfo(event)
      $(`#new-email`).modal()
    } else {
      if (window.confirm('Do you want to notify the contact?')) {
        params.notify_contact = true
      } else {
        params.notify_contact = false
      }

      const pagesRoute = Routes.dealership_appointment_path(dealershipId, event.id)
      fetch(`${pagesRoute}.json`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
        credentials: 'same-origin',
        body: JSON.stringify(params),
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            showToast(notification, 'success', 'Appointment updated successfully')
          })
        } else {
          showToast(notification, 'error', 'Appointment could not be updated')
        }
      })
    }
  }

  const updateAppointment = () => {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')

    let startAt = new Date(selectedInfo.start)
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const params = { appointment: { starts_at: startAt, timezone: timezone } }

    params.notify_assignee = notifyAssignee
    params.notify_contact = notifyContact

    const pagesRoute = Routes.dealership_appointment_path(dealershipId, selectedInfo.id)
    fetch(`${pagesRoute}.json`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
      body: JSON.stringify(params),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          showToast(notification, 'success', 'Appointment updated successfully')
          loadData()
          setEmailModalOpened(false)
          $(`#new-email`).modal('hide')
        })
      } else {
        showToast(notification, 'error', 'Appointment could not be updated')
      }
    })
  }

  const renderEventContent = (eventInfo) => {
    const { type, user_id } = eventInfo.event.extendedProps
    if (type === 'unavailable_interval') {
      return (
        <>
          <div style={{ whiteSpace: 'normal', backgroundColor: 'grey' }}>
            <i>Unavailable</i>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div style={{ whiteSpace: 'normal' }}>
            <i>{eventInfo.event.title}</i>
          </div>
        </>
      )
    }
  }

  const handleClose = () => {
    setOpened(false)
    $(`#new-appointment`).modal('hide')
  }

  const handleEmailModalClose = () => {
    setEmailModalOpened(false)
    //$(`#new-email`).modal('hide')
  }

  const handleSelectChange = (e) => {
    setSelectedAppointmentAssignee(e)
    let params = {
      dealership_id: dealershipId,
      appointment_assignee_id: e.value,
    }
    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')

    DealershipUsersUnavailableIntervalsService.getIntervals(params, csrf, dealershipId).then((data) => {
      setUnavailableIntervals(data)
    })

    AppointmentService.getAppointments(params, csrf, dealershipId).then((data) => {
      setAppointments(data.data.appointments)
    })
  }

  return (
    <div className="calendar">
      <Toast ref={notification} />
      <div>
        <AppointmentModal
          isOpen={opened}
          onClose={handleClose}
          selectedInfo={selectedInfo}
          navigateToLeadCluster={navigateToLeadCluster}
          navigateToContact={navigateToContact}
        />
      </div>
      <div>
        <EmailModal
          isOpen={emailModalOpened}
          onClose={handleEmailModalClose}
          selectedInfo={selectedInfo}
          notifyAssignee={notifyAssignee}
          notifyContact={notifyContact}
          setNotifyAssignee={setNotifyAssignee}
          setNotifyContact={setNotifyContact}
          updateAppointment={updateAppointment}
        />
      </div>
      <div className="calendar-main">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', zIndex: '2' }}>
          <h2>Appointments</h2>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AssigneeSelect
              appointmentAssignees={appointmentAssignees}
              selectedAppointmentAssignee={selectedAppointmentAssignee}
              handleSelectChange={handleSelectChange}
            />
            <button
              className="btn btn-outline-primary"
              onClick={navigateToUnavailableIntervals}
              style={{ padding: '5px 10px', marginLeft: '10px' }}
            >
              <i className="fa fa-calendar-alt" aria-hidden="true"></i> Unavailable Intervals
            </button>
          </div>
        </div>
        <div style={{ position: 'relative', zIndex: '1' }}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            ref={calendarRef}
            headerToolbar={{
              left: 'prev,next,today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            initialView="timeGridWeek"
            dayHeaderFormat={{ weekday: 'short', day: 'numeric', month: 'short' }}
            events={events}
            eventClick={(e) => {
              if (e?.event.extendedProps.type === 'unavailable_interval') {
                return
              } else {
                handleEventClick(e)
              }
            }}
            timeZone="local"
            editable={true}
            selectable={false}
            eventContent={renderEventContent}
            weekends={true}
            eventResize={handleEventResize}
            eventDrop={handleEventDrop}
            slotMinTime={'07:00:00'}
            slotMaxTime={'21:00:00'}
          />
        </div>
      </div>
    </div>
  )
}
